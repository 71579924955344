.postlist {
    width: 80%;
    margin: auto;
    text-align: center;
}

.post {
    width: 40%;
    margin: auto;
}

.page-content {
    width: 80%;
    margin: auto;
    text-align: center;
}

.notfound {
    text-align: center;
}